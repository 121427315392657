<template>
  <div class="page host-settlement-reservation-list">
    <div class="page-header">
      <h1 class="page-header__title">
        {{ profit.settled_date_start | moment('YYYY-MM-DD') }} ~ {{ profit.settled_date_end | moment('YYYY-MM-DD') }} 일자 <br />
        정산 이용내역 <br />
        정산완료 {{ docs?.length - cancel() }}건, 취소 {{ cancel() }}건
      </h1>
    </div>
    <div class="page-content">
      <div class="container">
        <div class="list-table list-table--primary">
          <div class="list-table__caption">금액 단위: 원(KRW)</div>
          <div class="list-table__header">
            <div class="list-table__cell">No.</div>
            <div class="list-table__cell">상점명</div>
            <div class="list-table__cell">결제날짜</div>
            <div class="list-table__cell">결제금액</div>
            <div class="list-table__cell">티켓상태</div>
            <div class="list-table__cell">정산비율</div>
          </div>
          <div class="list-table__body">
            <div class="list-table__row" v-for="(item, index) in docs" :key="index">
              <div class="list-table__cell">
                {{ index + 1 }}
              </div>
              <div class="list-table__cell">{{ profit.store_name_localed }}</div>
              <div class="list-table__cell">{{ item.paid_at | moment('YYYY-MM-DD HH:mm:ss') }}</div>
              <div class="list-table__cell">{{ item.settlement_total }}</div>
              <div class="list-table__cell">{{ $__t(item.ticket_status) }}</div>
              <div class="list-table__cell">{{ profit.profit_rate }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HostSettlementDetail',
  props: {
    id: Number | String
  },
  mounted() {},

  computed: {
    profit() {
      return this.$store.getters['hostSettlements/GET_ITEM']({ key: 'uid', value: this.id });
    },

    docs() {
      let items = [];

      if (Array.isArray(this.profit?.items) && this.profit?.items.length > 0) {
        // items = [...this.profit.items];
        items = _.cloneDeep(this.profit?.items);
      }
      // console.log('items', items);
      return items;
    }
  },
  methods: {
    cancel() {
      let cancelCount = 0;
      for (let i = 0; i < this.docs?.length; i++) {
        if (this.docs[i].status === 'CANCELLED') {
          cancelCount++;
        }
      }
      return cancelCount;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  padding: unit(24) unit(16);

  &__title {
    font-size: unit(22);
    font-weight: bold;
  }
}

.page-content {
  padding: unit(16);

  .container {
    overflow: auto;
    margin: auto;
    padding-bottom: unit(24);
  }
}
.list-table {
  display: table;
  border-collapse: collapse;
  width: 100%;

  &__header {
    display: table-header-group;
    text-align: center;

    .list-table__cell {
      background: #f1f5f5;
      font-weight: 500;
      margin: 0 auto;
    }
  }

  &__row {
    display: table-row;
  }

  &__body {
    display: table-row-group;
  }

  &__cell {
    display: table-cell;
    padding: unit(8);
    text-align: center;
    line-height: 1.5;
    vertical-align: middle;
    min-width: 120px;
    border: solid 1px #e1e4e6;

    button {
      word-wrap: break-word;
      word-break: break-all;
      width: auto;
      border: solid 1px #e1e4e6;
      line-height: 1.35;
      padding: unit(4);
      font-size: unit(10);
      color: #61676c;
      margin: auto;
      border-radius: $radius;
      cursor: pointer;
      @include shadow-box;

      &:hover {
        background: rgba(0, 0, 0, 0.125);
      }
    }

    &--nested {
      padding: 0;
      border: 0;
      display: table;
      table-layout: fixed;
      width: 100%;

      .list-table__header {
        border-top: solid 1px #e1e4e6;
        border-right: solid 1px #e1e4e6;
        border-bottom: solid 1px #e1e4e6;

        & > .list-table__cell {
          border: 0;
        }
      }

      .list-table__body {
        border-bottom: solid 1px #e1e4e6;

        & > .list-table__cell {
          border: 0;
          border-right: solid 1px #e1e4e6;
        }
      }

      .list-table__cell {
        width: 100px;
      }
    }

    &--primary {
      color: $color-primary;
    }
  }

  &__caption {
    display: table-caption;
    width: 100%;
    text-align: right;
    font-weight: 500;
  }
}
</style>
